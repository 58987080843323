import React from 'react';
import { Collapsible as OriginalCollapsible } from '@fiverr-private/disclosure';
import { useParentPropsOverrideContext } from '@fiverr-private/ui_utils';
import withImpression from '../helpers/withImpression';

const Collapsible = (props) => {
    const context = useParentPropsOverrideContext();

    return <OriginalCollapsible {...props} {...context} />;
};

export default withImpression(Collapsible);
